<template>
  <div>
    <channel-list-add-new
      :is-add-new-channel-sidebar-active.sync="isAddNewChannelSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card class="mb-0" no-body>
      <b-row class="p-2">
        <!-- Table Top -->
        <b-col cols="12" md="6">
          <span class="text-muted"
            >Show
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            entries</span
          >
        </b-col>
        <b-col cols="12" md="6" class="d-flex">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
          <b-button
            variant="primary"
            class="text-nowrap"
            @click="isAddNewChannelSidebarActive = true"
          >
            Add Channel
          </b-button>
        </b-col>
      </b-row>
      <b-table
        ref="refChannelListTable"
        class="position-relative"
        :items="fetchChannels"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Channels -->
        <template #cell(channels)="data">
          <b-media vertical-align="center">
            <span
              :to="{
                name: 'marketing-campaigns-campaign',
                params: { id: data.item.id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.channels }}
            </span>
          </b-media>
        </template>

        <!-- Column: Budget -->
        <template #cell(spentBudget)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.spentBudget }}
            </span>
          </b-media>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to
              {{ perPage }}
              of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalChannels"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BPagination,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useChannelsList from "./useChannelsList";
import channelStoreModule from "../channelStoreModule";
import ChannelListAddNew from "./ChannelListAddNew.vue";

export default {
  components: {
    ChannelListAddNew,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BFormInput,

    vSelect,
  },
  setup() {
    // eslint-disable-next-line camelcase
    const Channel_APP_STORE_MODULE_NAME = "app-channel";

    // Register module
    // eslint-disable-next-line curly
    if (!store.hasModule(Channel_APP_STORE_MODULE_NAME))
      // eslint-disable-next-line nonblock-statement-body-position
      store.registerModule(Channel_APP_STORE_MODULE_NAME, channelStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Channel_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(Channel_APP_STORE_MODULE_NAME);
      }
    });

    const isAddNewChannelSidebarActive = ref(false);

    const {
      fetchChannels,
      tableColumns,
      perPage,
      currentPage,
      totalChannels,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChannelListTable,
      refetchData,
    } = useChannelsList();

    return {
      // Sidebar
      isAddNewChannelSidebarActive,

      fetchChannels,
      tableColumns,
      perPage,
      currentPage,
      totalChannels,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChannelListTable,
      refetchData,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.add {
  padding: 0.715rem 0.736rem;
  transform: translateY(-53px);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
