<template>
  <div id="channels">
    <channels-list />
  </div>
</template>

<script>
import ChannelsList from "./channels-list/ChannelsList.vue";

export default {
  name: "Channels",
  components: {
    ChannelsList,
  },
};
</script>
