import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChannels(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/marketing/channels", { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchChannel(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/marketing/channels/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    addChannel(ctx, channelData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/marketing/channels", { channel: channelData })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
